import React from "react"

import Playlist from "../components/playlist"

import imgbanner from "../images/index/thumb/loveandbeyond.jpg"
import allcategories from "../content/categorytitles.json"
import videolist from "../content/playlistloveandbeyond.json"


const LoveandbeyondPage = ({location}) => (
  <Playlist
    location={location}
    headingClassName={"color-text-secondary"}
    path={"loveandbeyond"}
    bannerimg={imgbanner}
    title={allcategories.loveandbeyond.title}
    writeup={allcategories.loveandbeyond.description} 
    videolist={videolist} />
)

export default LoveandbeyondPage
